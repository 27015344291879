import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/vifor/vifor.png"
import vifor1 from "../../../assets/images/portfolio_items/vifor/vifor1.png"
import vifor2 from "../../../assets/images/portfolio_items/vifor/vifor2.png"
import vifor3 from "../../../assets/images/portfolio_items/vifor/vifor3.png"
import vifor4 from "../../../assets/images/portfolio_items/vifor/vifor4.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Vifor Pharma - Drupal Development Pharma'} 
    description="Global Drupal website development for Vifor Pharma: discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/vifor-pharma'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Vifor Pharma, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, Vifor Pharma"/>
            <div className="title"><h1>Vifor Pharma<span></span></h1>
            <h2>Global Drupal website development on Acquia Site Factory</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>We are digital agency of record for Vifor Pharma and manage all global website development in Drupal on Acquia Site Factory.</p>
        <div className="portfolio-images">
            <img src={vifor1} alt="Vifor Pharma, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, Vifor Pharma"/>
            <img src={vifor4} alt="Vifor Pharma, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, Vifor Pharma"/>
            <img src={vifor2} alt="Vifor Pharma, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, Vifor Pharma"/>
            <img src={vifor3} alt="Vifor Pharma, ICON Worldwide, Drupal website development, portfolio" title="ICON Worldwide portfolio, Drupal website development, Vifor Pharma"/>
        </div>
        <WorkFooter previous="credit-suisse-football" next="saut"/>
        </div>
    </div>
</div>